const cssGridTableClass = 'css-grid-table';
const ascendingOrderClass = 'headerSortUp';
const descendingOrderClass = 'headerSortDown';

/*
	NOTE: Sort order numbers are
	0 : No sort / default order
	1 : ascending,
	2 : descending
*/


application.tableSorter = new TableSorter();
function TableSorter()
{
	var self = this;
	
	var sortableTables;

	self.ready = function()
	{
		sortableTables = [];
		$( '.sortable-table' ).each( function(){
			sortableTables.push( new SortableTable( $(this) ) );
		} );
	};

	ApplicationObject.call( this );
}
TableSorter.prototype = Object.create( ApplicationObject.prototype );

function SortableTable( element )
{
	var observer;
	var observerConfig = { attributes: true, childList: true, subtree: true };

	var tableDataTarget = $(element).attr( 'sort-target' ) ? $( '#' + $(element).attr( 'sort-target' ) ) : element;

	var sortHeads;
	var tableDataElements;

	var initialSortIndex;

	var isCssGridTable = false;

	function initialize()
	{
		// This keeps the observer from triggering a mutation callback due to jquery triggering a mutation with $.find because ¯\_(ツ)_/¯
		if( observer != undefined )
			observer.disconnect();

		isCssGridTable = element.hasClass( cssGridTableClass );

		sortHeads = element.find( '.sort-head' );

		if( isCssGridTable )
		{
			console.log("Is CSS GRID");
			tableDataElements = tableDataTarget.find( 'div[data-column-index]:not( .css-table-header )' );
		}
		else
		{
			tableDataElements = tableDataTarget.find( 'tbody' ).find( 'tr' ).toArray().slice();
		}

		// Find initial sort order from class if no attribute was defined
		// These lines need to be before adding the mutation observer, because jquery triggers a mutation with $.find - again because ¯\_(ツ)_/¯
		var sortHead = $(element.find( '.sort-head.' + ascendingOrderClass + ', .sort-head.' + descendingOrderClass )[0]);
		var initialSortIndex = getSortHeadIndex( sortHead[0] );
		var initialSortOrder = sortHead.hasClass( ascendingOrderClass ) ? 1 : sortHead.hasClass( descendingOrderClass ) ? 2 : -1;

		addObserver();
		addSortHeadEvents();

		console.log( initialSortIndex + " : " + initialSortOrder );

		if( initialSortIndex != -1 && initialSortOrder != -1 )
		{
			sort( initialSortIndex, initialSortOrder );
		}
	}
	initialize();

	function addObserver(){
		if( observer == undefined )
		{
			var callback = function( mutationsList ) {
				if( mutationsList.length > 0 )
				{
					// console.log("Mutated");
					initialize();
				}
			};
			observer = new MutationObserver( callback );
		}
		observer.observe( element[0], observerConfig );
	}

	function addSortHeadEvents(){
		sortHeads.off( 'click' );
		sortHeads.on( 'click', function() {
			var index = getSortHeadIndex( $(this)[0] );
			var order = toggleColumnSortOrder( $(this) );
			sort( index, order );
		} );
	}

	function sort( index, order )
	{
		// Before sorting, disconnect the observer to stop mutation conflicts
		if( observer != undefined )
				observer.disconnect();

		console.log( 'Sort Column Index: ' + index );

		index = ( index == undefined ) ? 0 : index;
		order = ( order == undefined ) ? 0 : order;	

		sortColumnByIndex( index, order, function(){
			// After sorting, reconnect the observer
			if( observer != undefined )
				observer.observe( element[0], observerConfig );
		} );
	}

	function getSortHeadIndex( sortHead )
	{
		var index = 0;

		if( isCssGridTable )
			index = $( sortHead ).attr( 'data-column-index' );
		else
		{
			index = $(sortHead).parent( 'tr' ).find( 'th' ).index( $(sortHead) );
		}
		return index;
	}

	function toggleColumnSortOrder( column )
	{
		column.siblings().removeClass( ascendingOrderClass ).removeClass( descendingOrderClass );

		if( !column.hasClass( ascendingOrderClass ) && !column.hasClass( descendingOrderClass ) )
		{
			column.addClass( ascendingOrderClass );
		}
		else if( column.hasClass( ascendingOrderClass ) )
		{
			column.removeClass( ascendingOrderClass );
			column.addClass( descendingOrderClass );
		}
		else if( column.hasClass( descendingOrderClass ) )
		{
			column.removeClass( descendingOrderClass );
			column.addClass( ascendingOrderClass );
		}

		return column.hasClass( ascendingOrderClass ) ? 1 : column.hasClass( descendingOrderClass ) ? 2 : 0;
	}

	function sortColumnByIndex( index, sortOrder, onFinished )
	{	
		if( isCssGridTable )
		{
			var columnItems = [];
			for( var i = 0; i < tableDataElements.length; i++ )
			{
				if( $( tableDataElements[i] ).attr( 'data-column-index' ) == index )
				{
					columnItems.push( $( tableDataElements[i] ) );
				}
			}			

			columnItems.sort( function( a, b ){
				return ParseSortItems( a, b );
			} );

			if( sortOrder == 2 )
				columnItems.reverse();

			for( i = 0; i < columnItems.length; i++ )
			{
				var rowId = columnItems[i].attr( 'data-row-id' );
				columnItems[i].css( 'order', i );
				columnItems[i].siblings( 'div[data-row-id=' + rowId + ']' ).css( 'order', i );

				var isOdd = !( i % 2 == 0 ); // jshint ignore:line
				columnItems[i].attr( 'data-row-odd', isOdd );
				columnItems[i].siblings( 'div[data-row-id=' + rowId + ']' ).attr( 'data-row-odd', isOdd );
			}
		}
		else
		{
			var tbody = tableDataTarget.find( 'tbody' );

			// console.group( 'table values' );
			tableDataElements.sort( function( a, b ){
				var tdA = $( $( a ).find('td, tr').get( index ) );
				var tdB = $( $( b ).find('td, tr').get( index ) );
				
				var items = ParseSortItems( tdA, tdB );
				
				return items
			} );
			// console.groupEnd();

			if( sortOrder == 2 )
				tableDataElements.reverse();

			tbody.empty();
			for( var j = 0; j < tableDataElements.length; j++ )
			{
				tbody.append( tableDataElements[j] );
			}	
		}	

		if( onFinished != undefined && typeof onFinished == "function" )
			onFinished();
	}
}

/* 	--------------------------------------------------------------------
	Sort Functions
	-------------------------------------------------------------------- */
function ParseSortItems( a, b )
{
	var multipleWhiteSpace = new RegExp("\\s\\s+", "g");

	var tA = $(a).text();
	var tB = $(b).text();

	tA = tA.trim().replace( multipleWhiteSpace, ' ' );
	tB = tB.trim().replace( multipleWhiteSpace, ' ' );

	if( $(a).find('[data-sort-value]').attr( 'data-sort-value' ) !== undefined )
		tA = $(a).find('[data-sort-value]').attr( 'data-sort-value' );
	if( $(b).find('[data-sort-value]').attr( 'data-sort-value' ) !== undefined )
		tB = $(b).find('[data-sort-value]').attr( 'data-sort-value' );

	tA = ParseCompareValue( tA );
	tB = ParseCompareValue( tB );

	// console.log( tA + " : " + tB );

	var result = (tA < tB) ? -1 : (tA > tB) ? 1 : 0;

	return result;
}

function ParseCompareValue( val )
{
	// var dateFormat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-](\d{4}|\d{2})$/;
	var dateFormat = /^(\d{1,2})[\/\-](\d{1,2})[\/\-](\d{4})$/;
	var monthYearFormat = /\b(?:Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)|Apr(?:il)|May|Jun(?:e)|Jul(?:y)|Aug(?:ust)|Sep(?:tember)|Oct(?:ober)|Nov(?:ember)|Dec(?:ember)?) (?:19[7-9]\d|2\d{3})(?=\D|$)/
	var monthDayYearFormat = /\b(?:Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)|Apr(?:il)|May|Jun(?:e)|Jul(?:y)|Aug(?:ust)|Sep(?:tember)|Oct(?:ober)|Nov(?:ember)|Dec(?:ember)?) ([0-9]*(\,)?)? (?:19[7-9]\d|2\d{3})(?=\D|$)/

	if( val.match( dateFormat ) || val.match( monthYearFormat ) || val.match( monthDayYearFormat ) )
	{
		val = TryParseDate( val );
	}
	else
	{		
		val = val.replace( "%", "" ).replace( "$", "" ).replace( ",", "" );
		val = ( val == "N/A" || val == "--" || val == "" ) ? 0 : val;

		// This is for the pricing performance pages, where they sprinkle in an As Of value here and there. I hate it.
		if( typeof val == "string" )
			val = ( val.indexOf( 'As of' ) != -1 ) ? 0.001 : val;
		
		//console.log(val);
		if( !isNaN( parseFloat( val ) ) )
			val = parseFloat( val );
	}

	return val;
}

function TryParseDate( potentialDate )
{
	// console.log( "trying to sort by date" );
	var date = Date.parse( potentialDate );

	if( !isNaN( date ) )
	{
		return date;
	}

	return undefined;
}
