function AjaxContent()
{
	var self			= this,
		$ajaxContent	= $('.ajax-content');

	self.ready = function()
	{
		//console.log('AjaxContent ready');

		if ( $ajaxContent.length ) {

			//console.log('ajax-content detected');

			$ajaxContent.each( function(){
				
				var $this			= $(this),
					thisURL			= $this.data('url'),
					thisMethod		= $this.data('method'),		// replace, append, prepend, after, before
					thisType		= $this.data('type'),		// GET or POST
					thisDataType	= $this.data('datatype'),	// xml, html, script, json, jsonp, text
					thisData		= $this.data('data');

				$.ajax({
					url:		thisURL,
					type:		thisType,
					data:		thisData,
					datatype:	thisDataType
				})
				.done( function ( data ) {
					//console.log('done: ' + data);

					switch( thisMethod ) {
						case 'append':
							$this.append( data );
							break;
						case 'prepend':
							$this.prepend( data );
							break;
						case 'after':
							$this.after( data );
							break;
						case 'before':
							$this.before( data );
							break;
						default:
							$this.html( data );
					}
				})
				.fail( function ( jqXHR, textStatus, errorThrown ) {
					console.log(jqXHR);
					console.log('textStatus: ' + textStatus);
					console.log('errorThrown: ' + errorThrown);
				});
			});

		}
	};

	ApplicationObject.call( this );
}
AjaxContent.prototype = Object.create( ApplicationObject.prototype );