application.utilities = new Utilities();
function Utilities()
{

	var self = this;

	self.awake = function()
	{
		
	};

	self.ready = function()
	{
		FixBootstrapAccordions();
		TwitterStringToLinks();
		PDFLinkFormatter();
		AdvancedCollapseButtons();
		self.AdvisorURLConverter();
		SecondaryNav();
		self.deeplinkTabnavs();
		EnableCollapseExitHiding();
		CollapseClose();
		MagnifyImages();
		ScrollOffset();
	};

	/* BlueConic helper functions */
	self.BlueConic = {
		// Store whether or not blueConicClient is loaded
		loaded: false,

		// Returns the value of a BlueConic profile property as a Promise
		// Params:
		// 	propertyName: (String) Name of the BlueConic profile property
		// Usage: application.utilities.BlueConic.getProfileProperty('property_name').then(function(propertyValue) { console.log(propertyValue); });
		getProfileProperty: function( propertyName ) {
			return new Promise( function( resolve, reject ) {
				function doBlueConicWork() {

					// Set the loaded flag
					self.BlueConic.loaded = true;

					// Get the BlueConic profile
					var bcProfile = blueConicClient.profile.getProfile();

					bcProfile.loadValues( propertyName, this, function()
					{
						var bcPropertyValue	= bcProfile.getValues( propertyName );

						resolve( bcPropertyValue );
					} );
				}
				if (typeof window.blueConicClient !== 'undefined' &&
					typeof window.blueConicClient.event !== 'undefined' &&
					typeof window.blueConicClient.event.subscribe !== 'undefined')
				{
					// BlueConic is already loaded
					doBlueConicWork();
				} else {
					// Not yet loaded; wait for the "onBlueConicLoaded" event
					window.addEventListener('onBlueConicLoaded', function () {
						// BlueConic is now loaded
						doBlueConicWork();
					}, false);
				}
			});
		},
					
		// Set the value of a BlueConic profile property
		// Params:
		// 	propertyName: (String) Name of the BlueConic profile property
		// 	propertyValue: (String) Value of the BlueConic profile property
		// Usage: application.utilities.BlueConic.setProfileProperty('property_name', 'property_value');
		setProfileProperty: function( propertyName, propertyValue ) {
			function doBlueConicWork() {
				// Get the BlueConic profile
				let bcProfile = blueConicClient.profile.getProfile();

				bcProfile.setValue( propertyName, propertyValue );
				blueConicClient.profile.updateProfile( this, function() { /* Profile is now persistent */ });
			}

			// Is BlueConic loaded?
			if (typeof window.blueConicClient !== 'undefined' &&
				typeof window.blueConicClient.event !== 'undefined' &&
				typeof window.blueConicClient.event.subscribe !== 'undefined')
			{
				// BlueConic is already loaded
				doBlueConicWork();
			} else {
				// Not yet loaded; wait for the "onBlueConicLoaded" event
				window.addEventListener('onBlueConicLoaded', function () {
					// BlueConic is now loaded
					doBlueConicWork();
				}, false);
			}
		}
	};

	self.getParameterByName = function ( name, url ) {
		if (!url) {
		  url = window.location.href;
		}
		name = name.replace(/[\[\]]/g, "\\$&");
		var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, " "));
	};

	self.TwitterFormatLink = function(a)
	{
		return a.replace(/[a-z]+:\/\/[a-z0-9-_]+\.[a-z0-9-_:~%&amp;\?\/.=]+[^:\.,\)\s*$]/ig, function(b) {
			return '<a class="tweet-link" target="_blank" href="' + b + "\">" + ((b.length > 25) ? b.substr(0, 24) + "..." : b) + "</a>";
		});
	};
	self.TwitterFormatAt = function(a)
	{
		return a.replace(/(^|[^\w]+)\@([a-zA-Z0-9_]{1,15})/g, function(b, d, c) {
			return d + '@<a class="tweet-link" target="_blank" href="http://twitter.com/' + c + "\">" + c + "</a>";
		});
	};
	self.TwitterFormatHash = function(a)
	{
		return a.replace(/(^|[^\w'"]+)\#([a-zA-Z0-9_]+)/g, function(b, d, c) {
			return d + '#<a class="tweet-link" target="_blank" href="https://twitter.com/search?q=%23' + c + "\">" + c + "</a>";
		});
	};

	// Returns a converted query substring into a usable object
	// Params:
	// window.location.search.substring(1)

	/*
	 * Returns a converted query substring into a usable object
	 * @param {string} query - String to be converted into query string object
	 * @returns {object}
	 * Usage: var queryObj = parseQueryString(window.location.search.substring(1));
	 */
	self.parseQueryString = function(query) {
		var vars = query.split("&");
		var query_string = {};
		for (var i = 0; i < vars.length; i++) {
			var pair = vars[i].split("=");
			var key = decodeURIComponent(pair[0]);
			var value = decodeURIComponent(pair[1]);
			if (typeof query_string[key] === "undefined") {
				query_string[key] = decodeURIComponent(value);
			} else if (typeof query_string[key] === "string") {
				var arr = [query_string[key], decodeURIComponent(value)];
				query_string[key] = arr;
			} else {
				query_string[key].push(decodeURIComponent(value));
			}
		}
		return query_string;
	}

	self.deeplinkTabnavs = function() {
		
		// Moving away from using hash to link into tabs and using a query string instead
		/* var hash = document.location.hash;
		if (hash)
			$('div[role="tablist"] a[href="' + hash + '"], ul[role="tablist"] a[href="' + hash + '"]').tab('show'); */

		var query = self.parseQueryString(window.location.search.substring(1));
		if (query.tabref)
			$('div[role="tablist"] a[href="#' + query.tabref + '"], ul[role="tablist"] a[href="#' + query.tabref + '"]').tab('show');
	}

	function FixBootstrapAccordions()
	{
		$('.navbar-nav .sub-nav-toggler, .top-nav-toggler').click( function(){
			var $this = $(this);

			$this.siblings('.nav-link').toggleClass('active');
		});

		$(document).on('click', '[data-toggle="collapse"]', function(){
			var $this = $(this);

			if ( $this.data( 'parent' ) ) {

				if ( $this.siblings('[data-toggle="collapse"]').length ) {
					$this.siblings('[data-toggle="collapse"]').each(function(){
						var $thisSibling = $(this);

						$thisSibling.addClass('collapsed');
						$( $thisSibling.data('target') ).attr("aria-expanded", "false").slideUp(300, function(){ $(this).removeClass('show').attr('style', ''); });
					});
				} else if ( $this.parent('li').siblings('li').children('[data-toggle="collapse"]').length ) {
					$this.parent('li').siblings('li').children('[data-toggle="collapse"]').each(function(){
						var $thisSibling = $(this);

						$thisSibling.addClass('collapsed');
						$thisSibling.siblings('.nav-link').removeClass('active');
						$( $thisSibling.data('target') ).attr("aria-expanded", "false").slideUp(300, function(){ $(this).removeClass('show').attr('style', ''); });
					});
				}
			}
		});
	}

	function TwitterStringToLinks()
	{
		var twitterFeed = $( '#twitter-feed' );

		if ( twitterFeed.length ) {

			twitterFeed.find( '.tweet-link-convert' ).each( function( e ){

				var tweetString = $(this).html();

				tweetString = self.TwitterFormatHash( self.TwitterFormatAt( self.TwitterFormatLink( tweetString )));

				$(this).html( tweetString );
			});
		}
	}

	self.AdvisorURLConverter = function()
	{
		if (typeof $.cookie('ValidAdvisorCookie') !== 'undefined') { /* If user has ever logged into Advisor site */

			/* Change fancy links to Advisor */
			$('a[data-advisor-href]').each( function(index) {
				$(this).attr('href', $(this).attr('data-advisor-href'));
			});

			var $advisorSelects = $( 'select.advisor-select' );

			if ( $advisorSelects.length ) {

				$advisorSelects.each( function() {
					var $thisOptions = $(this).find('option');

					$thisOptions.each( function() {
						var $thisOption = $(this),
							advisorValue = $thisOption.data('advisor-value');

						if ( typeof advisorValue !== 'undefined' ) {
							$thisOption.attr( 'value', advisorValue );
						} else {
							var thisValue = $thisOption.attr('value'),
								newValue = '';

							newValue = thisValue.replace( '/individual/', '/advisor/' );

							$thisOption.attr( 'value', newValue );
						}
					});
				});
			}

		} else {

			$('a[data-advisor-only]').each(function(index) {
				$(this).remove();
			});
		}
	};

	// Recursivly checks if two objects are the same structure and values
	// Params: 
	// 	a: (Object) Object to compare with 
	// 	b: (Object) Object to compare with
	self.isEquivalent = function( x, y )
	{
		if( x === y )
		{
			return true;
		}
		else if( ( typeof x == "object" && x != null ) && ( typeof y == "object" && y != null) )
		{
			if( Object.keys( x ).length != Object.keys( y ).length )
				return false;

			for( var prop in x )
			{
				if( y.hasOwnProperty( prop ) )
				{  
					if ( !self.isEquivalent( x[prop], y[prop] ) )
						return false;
				}
				else
					return false;
			}

			return true;
		}
		else 
			return false;
	};

	// PDFLinkFormatter
	// Formats anchor tags with '.pdf' in the href to have the proper classes, labels, and targets
	function PDFLinkFormatter()
	{
		$( 'a' ).each( function(){

			var anchor = $( this );
			
			if( anchor[0].hasAttribute("href") )
			{
				var skip = anchor.data('ignore-formatter');

				if( anchor[0].hasAttribute( 'class' ) )
				{
					if( anchor.attr( 'class' ).indexOf( 'btn' ) != -1 )
						skip = true;
				}

				if( anchor.children('img').length > 0 ) skip = true;

				if( !skip && anchor.attr( 'href' ).indexOf( '.pdf' ) != -1 )
				{
					if( anchor.html().toLowerCase().indexOf( 'pdf' ) == -1 &&
						( (anchor.next().length > 0 && anchor.next().html().toLowerCase().indexOf( 'pdf' ) == -1) ||
						anchor.next().length === 0 ) )
						anchor.after( ' <span class="doctype">(pdf)' );

					// If the target isn't _blank, make it so
					if( anchor.attr( 'target' ) != '_blank' )
						anchor.attr( 'target', '_blank' );
				}
			// }
			}
			
		} );
	}

	// AdvancedCollapseButtons
	// Lets you customize collapse button text and classes
	function AdvancedCollapseButtons()
	{
		var advancedCollapseButtons = $( '.advanced-collapse-button' );

		if ( advancedCollapseButtons.length ) {

			$( advancedCollapseButtons ).each( function(){

				var advancedCollapseButton	= $( this );
				var toggleTextEl			= advancedCollapseButton.find( '[data-toggle-text]' ).length > 0 ? advancedCollapseButton.find( '[data-toggle-text]' ) : advancedCollapseButton;
				var	closedHTML				= toggleTextEl.html(),
					closedClasses 			= advancedCollapseButton.attr( 'class' ),
					openHTML 				= advancedCollapseButton.data( 'open-html' ),
					openClasses 			= advancedCollapseButton.data( 'open-classes' );

				console.log(toggleTextEl)
				advancedCollapseButton.click( function(){
					var closed = $(this).hasClass('collapsed');

					if ( closed ) {
						toggleTextEl.html( openHTML );
						advancedCollapseButton.attr( 'class', openClasses );
					} else {
						toggleTextEl.html( closedHTML );
						advancedCollapseButton.attr( 'class', closedClasses );
					}
				});						
				
			} );
		}
	}

	function SecondaryNav()
	{
		$('.secondary-nav li a').each(function(){
			var docPath = window.location.pathname;
			var strippedDocPath = docPath.replace(/\/+$/, "");
			var docPathArray = strippedDocPath.split( '/' );
			var docLastPart = docPathArray[docPathArray.length - 1];

			var secNavHref = $(this).attr('href');
			var strippedSecNavHref = secNavHref.replace(/\/+$/, "");
			var secNavHrefArray = strippedSecNavHref.split( '/' );
			var secNavHrefLastPart = secNavHrefArray[secNavHrefArray.length - 1];

			if (docLastPart == secNavHrefLastPart){
				$(this).addClass("active");
			}
		});
	}

	// Enables collapse content with the class collapse-on-exit to auto-hide when clicking off of it's element
	function EnableCollapseExitHiding()
	{
		$( document ).ready( function() {
		    $( document ).click( function( event ) {
		    	var clickTarget = $( event.target );
		    	$( '.collapse-on-exit' ).each( function(){
		    		var root = $(this);
		    		if( $(this).attr( 'data-clickoff-root-id' ) !== undefined && $(this).attr( 'data-clickoff-root-id' ) !== '' )
		    			root = $( $(this).attr( 'data-clickoff-root-id' ) );
		    		
		    		var clickedOff = ( root.has( clickTarget ).length == 0 );
		    		if( clickedOff && $(this).hasClass('show') )
		    		{
		    			$(this).collapse( 'hide' );
		    		}
		    	} );
		    } );
		} );
	}

	function CollapseClose()
	{
		$( '[data-collapse-close] a' ).on( 'click', function( e ){
			
			$(this).closest('.collapse').collapse('hide');

		} );
	}

	function MagnifyImages()
	{
		$( window ).resize( function(){
			$('.magnify').each( function(){
				$(this).height( $(this).width() / ( $(this).data('width') / $(this).data('height') ) )
			});
		} );
		$('.magnify')
			.on( 'mouseover', function() {
				$(this).children('.magnify-image').css({'transform': 'scale('+ $(this).attr('data-scale') +')'});
			})
			.on( 'mouseout', function() {
				$(this).children('.magnify-image').css({'transform': 'scale(1)'});
			})
			.on( 'mousemove', function( e ) {
				$(this).children('.magnify-image').css({'transform-origin': ((e.pageX - $(this).offset().left) / $(this).width()) * 100 + '% ' + ((e.pageY - $(this).offset().top) / $(this).height()) * 100 +'%'});
			})
			.each( function(){
				$(this)
					.height( $(this).width() / ( $(this).data('width') / $(this).data('height') ) )
					.append( '<div class="magnify-image"></div>' )
					.children('.magnify-image').css({'background-image': 'url('+ $(this).attr('data-image') +')'});
			});
	}

	function ScrollOffset()
	{
		if ( application.utilities.getParameterByName('so') ) {
			var scrollOffsetTime	= 5,
				scrollOffset		= application.utilities.getParameterByName('so');

			if ( application.utilities.getParameterByName('sot') ) {
				scrollOffsetTime = application.utilities.getParameterByName('sot');
			}

			setTimeout( function(){
					window.scrollBy( 0, scrollOffset )
				},
				scrollOffsetTime * 1000
			);
		}
	}

	ApplicationObject.call( this );
}
Utilities.prototype = Object.create( ApplicationObject.prototype );


/*
	Dictionary
	Collection of Key/Value pairs used for storing and retrieving data via unique IDs
*/
function Dictionary()
{

	var self = this;

	self.keys = [];
	self.values = [];

	self.length = 0;

	self.add = function( key, value )
	{
		if( self.keys.indexOf( key ) === -1 )
		{
			self.keys.push( key );
			self.values[ self.keys.length - 1 ] = value;
			self.length = self.keys.length;
		}
		else
		{
			debug.logWarning( 'Key already exists, updating key value' );
			self.set( key, value );
		}
	};

	self.remove = function( key )
	{
		if( self.keys.indexOf( key ) !== -1 )
		{
			var idx = self.keys.indexOf( key );
			self.keys.splice( idx, 1 );
			self.values.splice( idx, 1 );
			self.length = self.keys.length;
		}
	};

	self.get = function( key )
	{
		var ro = self.getByIndex( self.keys.indexOf( key ) );
		return ro;
	};

	self.getByIndex = function( i )
	{
		var val = self.values[ i ];
		if( val !== undefined ) return val;
		else
			debug.log( 'key not found in Dictionary' );
	};

	self.set = function( key, value )
	{
		if( self.keys.includes( key ) )
		{
			self.values[ self.keys.indexOf( key ) ] = value;
		}
		else
		{
			debug.logWarning( 'Key doesn\'t exist' );
			self.add( key, value );
		}
	};

}

/*
	Jump Menu Functionality
	This existed in many places before. Putting it here so the legacy stuff still works without needing to be added everywhere
*/
/* jshint ignore:start */
function MM_jumpMenu(target,selObj,restore){ //v4.0
	let val = selObj.options[selObj.selectedIndex].value;
	if(val == 'funds managed') return;
	(target == '_blank') ? window.open(val, '_blank').focus() : eval(target+".location='"+val+"'");
	if (restore) selObj.selectedIndex=0;
}
/* jshint ignore:end */

/*
	String Format emulator
	Replaces the bracketted "placeholders" with the arguments passed
	Usage:
	"Hello {0} {1}".format( 'Whoopi', 'Goldberg' );
	prints "Hello Whoopi Goldberg"
*/
String.prototype.format = function()
{
	var self = this,
		i = arguments.length;

	while (i--) {
		self = self.replace( new RegExp('\\{' + i + '\\}', 'gm'), arguments[i] );
	}
	return self;
};
