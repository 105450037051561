//
// Object.assign(obj1, obj2)
//
// - Doesn't support ES6 symbols since ES5 doesn't have them.
//
if (typeof Object.assign != 'function') {
	// Must be writable: true, enumerable: false, configurable: true
	Object.defineProperty(Object, "assign", {
		value: function assign(target, varArgs) { // .length of function is 2
			'use strict';
			if (target == null) { // TypeError if undefined or null
				throw new TypeError('Cannot convert undefined or null to object');
			}

			var to = Object(target);

			for (var index = 1; index < arguments.length; index++) {
				var nextSource = arguments[index];

				if (nextSource != null) { // Skip over if undefined or null
					for (var nextKey in nextSource) {
						// Avoid bugs when hasOwnProperty is shadowed
						if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
							to[nextKey] = nextSource[nextKey];
						}
					}
				}
			}
			return to;
		},
		writable: true,
		configurable: true
	});
}

// Foreach polyfill for IE11
if ('NodeList' in window && !NodeList.prototype.forEach) {
	console.info('polyfill for IE11');
	NodeList.prototype.forEach = function (callback, thisArg) {
		thisArg = thisArg || window;
		for (var i = 0; i < this.length; i++) {
			callback.call(thisArg, this[i], i, this);
		}
	};
}

// Report Validity form checking for IE11
if (!HTMLFormElement.prototype.reportValidity) {
	HTMLFormElement.prototype.reportValidity = function () {
		if (this.checkValidity()) return true;
		var btn = document.createElement('button');
		this.appendChild(btn);
		btn.click();
		this.removeChild(btn);
		return false;
	};
}
if (!HTMLInputElement.prototype.reportValidity) {
	HTMLInputElement.prototype.reportValidity = function () {
		if (this.checkValidity()) return true
		var tmpForm;
		if (!this.form) {
			tmpForm = document.createElement('form');
			tmpForm.style.display = 'inline';
			this.before(tmpForm);
			tmpForm.append(this);
		}
		var siblings = Array.from(this.form.elements).filter(function (input) {
			return input !== this && !!input.checkValidity && !input.disabled;
		}, this);
		siblings.forEach(function (input) {
			input.disabled = true;
		});
		this.form.reportValidity();
		siblings.forEach(function (input) {
			input.disabled = false;
		});
		if (tmpForm) {
			tmpForm.before(this);
			tmpForm.remove();
		}
		this.focus();
		this.selectionStart = 0;
		return false;
	};
}